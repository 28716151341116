*
{
    margin: 0;
    padding: 0;
}

html,
body
{
    overflow: hidden;
    color: #454C59;
    background: lightblue;
    font-family: monospace;
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
    z-index: -1;
}
